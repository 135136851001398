import React from 'react'
import { graphql} from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import HomeHeader from '../../components/theme/HomeHeader'
import RenderBlock from '../../components/blocks/RenderBlock'
import DidYouKnow from '../../components/common/DidYouKnow'
import Offers from '../../components/common/Offers'
import { Provider as EntryProvider } from '../../contest/context/EntryContext';

const IndexPage = ({ data }) => (
  <Layout
    meta={data.startPage.meta}
    urls={data.startPage.urls}
    locale='en'
  >
    <EntryProvider>
      <HomeHeader
        frontHeaderText={data.startPage.title_html}
        urls={data.startPage.urls}
        locale='en'
      />
      <RenderBlock location="home" blocks={data.startPage.blocks} />

      <DidYouKnow blogPosts={data.prismic.allHome_solution_featureds.edges[0].node} locale="en" />
      <Footer
        extraRules={
          '<sup>†</sup>Royale<sup>®</sup> tissue products are manufactured by Irving Consumer Products Limited, an affiliate of J.D. Irving, Limited. J.D. Irving, Limited and affiliated corporations have planted a billion trees since 1957. ' +
          '<br/><br/>*Each year, forests managed by J.D. Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup>  tissue products.'
        }
      />
      <Offers />
    </EntryProvider>
  </Layout>
)

export const query = graphql`
query {
  startPage(guid: { eq: "54319571-6668-471c-bc49-71b26b4b0ddf" }, locale: { eq: "en" }) {
    title_html
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    slides {
      name
      link
      image_url
      mobile_image_url
    }
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...promotionBlockFragment
    }
    meta {
      description,
      keywords
      title
    }
  }
  prismic {
    allHome_solution_featureds(lang: "en-ca") {
      edges {
        node {
          blog_1 {
            ... on PRISMIC_Home_solution_article {
              title
              _meta {
                uid
                lang
              }
              preview_image
            }
          }
          blog_2 {
            ... on PRISMIC_Home_solution_article {
              title
              _meta {
                uid
                lang
              }
              preview_image
            }
          }
          blog_3 {
            ... on PRISMIC_Home_solution_article {
              title
              _meta {
                uid
                lang
              }
              preview_image
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage
